<template>
  <g fill="none" fill-rule="evenodd">
    <g stroke="#FFF">
      <g>
        <g>
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
            d="M14.432 2.97L13.184.997C12.92.69 12.56.5 12.156.5H7.844c-.404 0-.765.191-1.028.498L5.568 2.969c-.263.307-.601.531-1.005.531H1.75c-.398 0-.78.158-1.06.44C.407 4.22.25 4.601.25 5v9c0 .398.158.78.44 1.06.28.282.662.44 1.06.44h16.5c.398 0 .78-.158 1.06-.44.282-.28.44-.662.44-1.06V5c0-.398-.158-.78-.44-1.06-.28-.282-.662-.44-1.06-.44h-2.766c-.405 0-.79-.224-1.052-.53z"
            transform="translate(-176 -603) translate(162.5 587) translate(15 17)"
          />
          <path
            stroke-width="1.5"
            d="M10 12.5c2.071 0 3.75-1.679 3.75-3.75C13.75 6.679 12.071 5 10 5 7.929 5 6.25 6.679 6.25 8.75c0 2.071 1.679 3.75 3.75 3.75z"
            transform="translate(-176 -603) translate(162.5 587) translate(15 17)"
          />
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3.813 3.406L3.813 2.375 2.688 2.375 2.688 3.406"
            transform="translate(-176 -603) translate(162.5 587) translate(15 17)"
          />
        </g>
      </g>
    </g>
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
